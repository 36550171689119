import { ref } from '@nuxtjs/composition-api';
import axios from 'axios';

export function useBlogPosts() {
  const posts = ref([]);

  const fetchPostsForCategories = async (categories) => {
    posts.value = [];
    for (const category of categories) {
      try {
        const headers = {
          Authorization: `Bearer ${process.env.STRAPI_SECRET}`,
        };
        const response = await axios.get(
          `${process.env.STRAPI_URL}/api/blogs?pagination[pageSize]=6&filters[blog_categories][id][$eq]=${category.id}&populate=*&sort=id:desc`,
          { headers }
        );
        posts.value.push(response.data.data);
      } catch (error) {
        console.error(`Error fetching posts for category ${category.id}:`, error);
      }
    }
  };

  return {
    posts,
    fetchPostsForCategories,
  };
}
