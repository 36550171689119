<template>
  <div v-if="isLoaded" class="card">
    <BlogHeader />
    <b-tabs card>
      <b-tab v-for="(category, index) in categories" :key="category.id" :title="$t(category.attributes.name)"
        class="px-0">
        <BlogList :posts="posts[index]" :category="category" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { useBlogCategories } from '~/composables/useBlogCategories/index';
import { useBlogPosts } from '~/composables/useBlogPosts/index';
import BlogHeader from './BlogHeader.vue';
import BlogList from './BlogList.vue';

export default defineComponent({
  name: 'StrapiBlog',
  components: {
    BlogHeader,
    BlogList,
  },
  setup() {
    const { categories, fetchCategories } = useBlogCategories();
    const { posts, fetchPostsForCategories } = useBlogPosts();
    const isLoaded = ref(false);

    onMounted(async () => {
      await fetchCategories();
      await fetchPostsForCategories(categories.value);
      isLoaded.value = true;
    });

    return {
      categories,
      posts,
      isLoaded,
    };
  },
});
</script>
