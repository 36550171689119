<template>
  <div class="blog-title">
    <div class="row py-3">
      <div class="col-6">
        <h3>{{ $t("Blog") }}</h3>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <a :href="`${baseUrl}${currentLocale}/blog`" class="">{{ $t("See Blog All") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, useContext } from "@nuxtjs/composition-api";

export default defineComponent({
	name: "BlogHeader",
	setup() {
		const { app } = useContext();
		const currentLocale = app.i18n.localeProperties.code;
		const baseUrl = process.client ? `${window.location.origin}/` : "/";

		return {
			currentLocale,
			baseUrl,
		};
	},
});
</script>