import { ref } from '@nuxtjs/composition-api';
import axios from 'axios';

export function useBlogCategories() {
  const categories = ref([]);

  const fetchCategories = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${process.env.STRAPI_SECRET}`,
      };
      const response = await axios.get(`${process.env.STRAPI_URL}/api/blog-categories?sort=id:desc`, { headers });
      categories.value = response.data.data;
    } catch (error) {
      console.error('Error fetching blog categories:', error);
    }
  };

  return {
    categories,
    fetchCategories,
  };
}
