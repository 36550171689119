<template>
  <div class="blog-card h-100">
    <div class="row h-100">
      <div class="image-card col-lg-12">
        <SfImage v-if="post.attributes.thumbnail && post.attributes.thumbnail.data" class="sf-product-card__image"
          :alt="post.attributes.title" :src="post.attributes.thumbnail.data.attributes.url" :width="991" :height="730"
          :tag="category.attributes.name" loading="lazy" />
      </div>
      <div class="post-detail d-flex flex-column col-lg-12">
        <div class="px-3">
          <nuxt-link :to="localePath(`/blog/${post.attributes.url}`)" class="">
            <h3 class="post-title">{{ post.attributes.title }}</h3>
          </nuxt-link>
          <p class="short-description">{{ shortContent }}</p>
          <div class="post-info d-flex align-items-center">
            <p>By {{ post.attributes.blog_author.data.attributes.name }} | {{ post.attributes.views }} Views</p>
            <nuxt-link :to="localePath(`/blog/${post.attributes.url}`)" class="btn btn-link">
              {{ $t("Read More") }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import SfImage from "~/modules/catalog/product/components/SfImage.vue";

export default defineComponent({
  name: 'BlogCard',
  components: {
    SfImage,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const shortContent = computed(() => {
      const content = props.post.attributes.short_content || props.post.attributes.full_content;
      return content.replace(/<[^>]*>?/g, '');
    });

    return {
      shortContent,
    };
  },
});
</script>