<template>
  <div class="blog-detail">
    <div class="blog-slider row">
      <div v-for="post in posts" :key="post.id" class="col-lg-4 py-3">
        <BlogCard :post="post" :category="category" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import BlogCard from './BlogCard.vue';

export default defineComponent({
  name: 'BlogList',
  components: {
    BlogCard,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
});
</script>